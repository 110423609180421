<template>
  <AdminTemplate>
    <el-col :span="12" :offset="6">
      <div class="mg-y-2 text-right">
        <el-button type="success" @click="exportData" v-if="step.qId == 16">
          Export
        </el-button>
      </div>
      <h1 class="text-center mg-t-3 mg-b-1">จัดลำดับคะแนน (TOP 10)</h1>
      <div>
        <el-row v-for="(data, index) in userList" :key="index" class="mg-y-5">
          <el-col :span="2">
            <div class="w-100 m-h-20">
              <span
                v-if="
                  data.old > 0 &&
                  data.old != data.rating &&
                  data.old != index + 1 &&
                  data.rating > 0
                "
                >จาก {{ data.old }}</span
              >
            </div>
          </el-col>
          <el-col :span="1">
            <div
              class="m-h-20"
              :class="data.old < data.rating ? 'text-danger' : 'text-success'"
            >
              <span
                v-if="
                  data.old > 0 &&
                  data.old != data.rating &&
                  data.old != index + 1 &&
                  data.rating > 0
                "
              >
                <i
                  class="fas fa-chevron-circle-down"
                  v-if="data.old < data.rating && step.qId > 1"
                ></i>
                <i
                  class="fas fa-chevron-circle-up"
                  v-if="data.old > data.rating && step.qId > 1"
                ></i>
              </span>
            </div>
          </el-col>
          <el-col :span="3"> ลำดับที่ {{ index + 1 }} </el-col>
          <el-col :span="1" class="m-h-20">
            <span v-if="data.isPoint == 1000"
              ><i class="fas fa-fire text-danger"></i
            ></span>
          </el-col>
          <el-col :span="17">
            <div
              class="is-flex js-between border-bottom"
              :class="index < 3 ? 'border-danger' : ''"
            >
              <div>
                {{ data.name }}
              </div>
              <div>
                {{ data.point | comma }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" :offset="6" class="mg-t-3">
            <el-button
              type="primary"
              class="w-100"
              @click="step.qId == 16 ? endRoom() : nextStep()"
              >{{
                step.qId == 16 ? "จบการทำข้อสอบ" : "ทำข้อสอบข้อต่อไป"
              }}</el-button
            >
          </el-col>
        </el-row>
      </div>
    </el-col>
  </AdminTemplate>
</template>
<script>
import { HTTP } from "@/service/axios.js";
import AdminTemplate from "@/template/VoteAdminTemplate";
export default {
  components: {
    AdminTemplate
  },
  props: {
    routeParams: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.getRang();
  },
  methods: {
    exportData() {
      let obj = {
        roomId: this.room.roomId
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post("export/answer", obj).then(res => {
        // console.log('api/export/answer',res)
        if (res.data.success) {
          var name = "_blank";
          var specs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"];
          const url = this.linkUrl + "system/" + res.data.obj;
          //const win = window.open(url, specs);
          var createA = document.createElement("a");
          createA.setAttribute("href", url);
          createA.setAttribute("target", "_blank");
          createA.click();
        }
      });
    },
    checkAnswer(id) {
      let check = false;
      let filter = this.userList.filter(a => a.isPoint == 1000);
      if (filter.length > 0) {
        filter[0].id == id ? (check = true) : null;
      }
      return check;
    },
    getRang() {
      let obj = {
        roomId: this.room.roomId,
        qId: this.step.qId != "undefined" ? this.step.qId : this.step.qid
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post("rating", obj).then(res => {
        // console.log('rating',res)
        if (res.data.success) {
          this.userList = res.data.obj;
          // .sort((a, b) => {
          //     let modifier = -1;
          //     if (a.point < b.point) return -1 * modifier;
          //     if (a.point > b.point) return 1 * modifier;
          //     return 0;
          // });
          this.userList = this.userList.slice(0, 10);
        }
      });
    },

    endRoom() {
      this.$confirm("คุณต้องการ จบการทำข้อสอบ?", "Warning", {
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        type: "warning"
      })
        .then(() => {
          let obj = {
            roomId: this.room.roomId,
            qId: 17,
            attId: 1,
            total: false,
            state: "end"
          };
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.post(`state`, obj).then(res => {
            if (res.data.success) {
              HTTP.put(`end/${this.room.roomId}`)
                .then(result => {
                  if (result.data.success) {
                    this.setState(obj);
                    this.$message({
                      type: "success",
                      message: "การทำข้อสอบสำเร็จ"
                    });
                    this.$router.push("/create-room").catch(err => {});
                  }
                })
                .catch(err => {
                  console.log(`end/${this.room.roomId}`, err);
                });
            }
          });
        })
        .catch(() => {});
    },
    nextStep() {
      let qid;
      if (this.step.qId != "undefined") {
        qid = this.step.qId < 16 ? Number(this.step.qId) + 1 : "";
      } else {
        qid = this.step.qid < 16 ? Number(this.step.qid) + 1 : "";
      }
      let obj = {
        roomId: this.room.roomId,
        qId: qid,
        attId: 1,
        total: false,
        state: Number(this.step.qId) + 1 <= 7 ? "description" : "test"
      };
      let next = this.allExam.filter(a => a.no == Number(this.step.qId) + 1);
      if (next.length > 0) {
        obj.attId = 1;
      }
      if (this.step.qId < 16) {
        this.setState(obj);
        if (next[0].step.length > 0) {
          this.$router.push("/slide");
        } else {
          this.$router.push("/testing");
        }
      }
    }
  },
  computed: {
    allExam() {
      return this.$store.state.exam;
    },
    exam() {
      if (typeof this.routeParams != "undefined") {
        let no = this.step.qId;
        let step = this.routeParams.step;
      }
      let data = this.$store.state.exam.filter(a => a.no == this.step.qId);
      if (data.length > 0) {
        return data[0];
      } else {
        return "";
      }
    }
  },
  data() {
    return {
      linkUrl: process.env.VUE_APP_BASE_URL,
      userList: []
    };
  }
};
</script>